<template>
  <div
    class="list-detail-container d-flex flex-column scroll-container"
    v-if="list"
  >
    <v-row>
      <v-col cols="12" sm="6">
        <h1 class="pl-3 pl-sm-0 py-3 py-sm-0">
          {{ list.name }}
        </h1>
        <p
          v-if="list.wishlistItems.length > 0"
          v-html="$t('profile.lists.totalCount', [list.wishlistItems.length])"
        ></p>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex justify-end align-end">
        <v-btn
          @click="goToGridView(list)"
          class="grid-view-btn rounded-pill mb-3"
          large
          outlined
          depressed
          ><v-icon color="primary" class="mr-1" size="26">$grid</v-icon
          >{{ $t("profile.lists.switchToGrid") }}</v-btn
        >
      </v-col>
    </v-row>

    <div
      v-if="list.wishlistItems.length + list.wishlistInactiveItems.length > 0"
      class="list rounded-md pa-4"
    >
      <!-- <div class="d-flex justify-end">
        <v-switch v-model="showSectors" label="Dividi per categoria"></v-switch>
      </div> -->
      <v-row
        no-gutters
        class="text-body-2 font-weight-semibold mb-3"
        v-if="$vuetify.breakpoint.smAndUp"
      >
        <v-col cols="4" sm="5" md="6" lg="6">
          {{ $t("lists.header.product") }}
        </v-col>
        <v-col cols="3" sm="4" md="2" lg="2">
          {{ $t("lists.header.unitPrice") }}
        </v-col>
        <v-col
          cols="4"
          sm="3"
          md="4"
          lg="4"
          xl="5"
          class="text-center text-md-left"
        >
          {{ $t("lists.header.qty") }}
        </v-col>
        <v-col md="2"></v-col>
      </v-row>
      <v-list v-if="list && !showSectors">
        <ListItem
          v-for="item in list.wishlistItems"
          v-bind:key="item.itemId"
          :item="item"
          :isActive="true"
          @removeItem="removeFromList"
          @update="updateList"
        ></ListItem>
        <ListItem
          v-for="item in list.wishlistInactiveItems"
          v-bind:key="item.itemId"
          :item="item"
          @removeItem="removeFromList"
          :isActive="false"
        ></ListItem>
      </v-list>

      <v-expansion-panels v-else v-model="panel" accordion flat multiple tile>
        <v-expansion-panel
          v-for="[categoryId, group] in itemGroups"
          v-bind:key="categoryId"
        >
          <v-expansion-panel-header
            v-on:click.prevent="() => {}"
            class="text-caption primary--text grey lighten-3 px-3"
          >
            <div class="d-flex align-center black--text">
              <span class="font-weight-semibold mr-2">{{ group.name }}</span>
              <span>
                {{ `(${group.products.length})` }}
              </span>
            </div>
            <template v-slot:actions>
              <v-icon small>
                <!-- {{ panel.includes(index) ? "$chevronUp" : "$chevronDown" }} -->
                $chevronDown
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ListItem
              v-for="item in group.products"
              v-bind:key="item.itemId"
              :item="item"
              :isActive="item.isActive"
              @removeItem="removeFromList"
              @update="updateList"
            ></ListItem>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-card
      light
      depresses
      elevation="0"
      v-if="list.wishlistItems.length + list.wishlistInactiveItems.length == 0"
    >
      <v-card-title class="text-body-1 px-0 py-4 font-weight-semibold">{{
        $t("lists.noProducts")
      }}</v-card-title>
    </v-card>
    <v-row
      no-gutters
      class="mt-6 mb-10"
      justify="center"
      justify-md="space-between"
    >
      <div>
        <v-btn
          depressed
          large
          :outlined="$vuetify.breakpoint.smAndDown"
          :to="{ name: 'Lists' }"
          color="transparent"
          :block="$vuetify.breakpoint.smAndDown"
          :class="
            $vuetify.breakpoint.smAndDown
              ? 'mb-3 primary--text'
              : 'px-0 primary--text'
          "
          plain
        >
          <v-icon class="mr-3">$arrowLeft</v-icon>
          <span class="default--text">{{
            $t("lists.button.backToLists")
          }}</span>
        </v-btn>
        <v-btn
          outlined
          large
          color="default"
          class="ml-4 empty-list-btn mb-3 mb-md-0 rounded-pill"
          :block="$vuetify.breakpoint.smAndDown"
          depressed
          :disabled="
            list.wishlistItems.length + list.wishlistInactiveItems.length == 0
          "
          @click="emptyList()"
        >
          <v-icon class="mr-1">$trashXmark</v-icon>
          {{ $t("lists.button.emptyList") }}
        </v-btn>
      </div>
      <div :class="$vuetify.breakpoint.smAndDown ? 'w-100' : ''">
        <v-btn
          class="ml-md-3 rounded-pill"
          large
          color="primary"
          :block="$vuetify.breakpoint.smAndDown"
          depressed
          @click="addAllToCart"
          :disabled="
            list.wishlistItems.length + list.wishlistInactiveItems.length == 0
          "
        >
          <v-icon class="mr-1">$cart</v-icon>
          {{ $t("lists.button.addAllToCart") }}
        </v-btn>
      </div>
    </v-row>
  </div>
</template>
<style lang="scss">
.empty-list-btn,
.grid-view-btn {
  background-color: #ffffff;
}
.list-detail-container {
  h1 {
    font-size: 34px;
  }
  .list {
    background-color: $white;
    .v-expansion-panel-content {
      &__wrap {
        padding: 0;
      }
    }
  }
  .v-list-item {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      border-bottom: 1px solid $border-color;
    }
  }
  .v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 20px;
  }
}
</style>
<script>
import ListItem from "@/components/lists/ListItem.vue";

import { mapActions } from "vuex";

import deliveryReactive from "~/mixins/deliveryReactive";

import ListService from "~/service/listService";

import map from "lodash/map";
import concat from "lodash/concat";
import groupBy from "lodash/groupBy";
import AnalyticsService from "~/service/analyticsService";

export default {
  name: "ListDetail",
  props: {
    listId: { type: [Number, String], required: true },
    showSectors: { type: Boolean, default: false }
  },
  data() {
    return {
      list: null,
      panel: []
    };
  },
  components: {
    ListItem
  },
  mixins: [deliveryReactive],
  computed: {
    itemGroups() {
      let groups = new Map();
      // if( this.list)
      this.list?.wishlistItems.forEach(item => {
        item.isActive = true;
      });
      this.list?.wishlistInactiveItems.forEach(item => {
        item.isActive = false;
      });
      let allWishlistProducts = [
        ...this.list?.wishlistItems,
        ...this.list?.wishlistInactiveItems
      ];
      allWishlistProducts.forEach(item => {
        let category = this.$store.getters["category/lookupCategory"](
          item.product.categoryId
        );
        if (category === null || category === undefined) {
          category = {
            categoryId: -1,
            name: "Senza categoria"
          };
        }
        if (groups.has(category.categoryId)) {
          groups.get(category.categoryId).products.push(item);
        } else {
          groups.set(category.categoryId, {
            name: category.name,
            products: [item]
          });
        }
      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.panel = Array.from(Array(groups.size).keys());
      return groups;
    }
  },
  methods: {
    ...mapActions({
      addProductsFromWishlist: "cart/addProductsFromWishlist",
      setCartItemInfo: "cart/setCartItemInfo"
    }),
    async fetchList() {
      this.list = await ListService.getList(this.listId, true);
      // Analytic's viewProducts() uses a method that expects an item prop (for getting its price), so I need to spread that structure...
      const wishListProducts = [
        ...this.list.wishlistInactiveItems.map(wi => ({
          ...wi.product,
          product: wi.product
        })),
        ...this.list.wishlistItems.map(wi => ({
          ...wi.product,
          product: wi.product
        }))
      ];
      console.log("wishListProducts", wishListProducts);
      if (wishListProducts.length) {
        AnalyticsService.viewProducts(wishListProducts, "Wishlist", 0);
      }
    },
    setItemGroups(array) {
      let temp = groupBy(array, "product.categoryId");
      this.itemGroups = map(temp, products => {
        let category = this.$store.getters["category/lookupCategory"](
          products[0].product.categoryId
        ).name;
        return {
          category,
          products
        };
      });
      this.panel = Array.from(Array(this.itemGroups.length).keys());
    },
    async removeFromList({ itemId, product }) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeItemFromList", {
          productName: product.name,
          productShortDescr: product.shortDescr
        })
      });
      if (res) {
        const newList = await ListService.removeItemFromList(
          this.list,
          itemId,
          product.productId,
          true
        );
        this.list = newList;
        this.setItemGroups(
          concat(this.list.wishlistInactiveItems, this.list.wishlistItems)
        );
      }
    },
    async emptyList() {
      const textConfirm = `Sei sicuro di voler svuotare la lista ${this.list.name}?`;

      let res = await this.$dialog.confirm({
        text: textConfirm
      });
      if (res) {
        const res = await ListService.emptyList(this.list);

        this.list = res;
      }
    },
    async addAllToCart() {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllToList")
      });
      if (res) {
        const result = await this.addProductsFromWishlist(this.listId);
        if (result && result.cartItems && result.cartItems.length > 0) {
          this.setCartItemInfo({
            items: result.cartItems,
            name: "accept_alternatives",
            value: global.config.acceptAlternatives
          });
        }
      }
    },
    async updateList({ newQuantity, item }) {
      const res = await ListService.updateProductsInList(
        this.listId,
        item,
        newQuantity,
        true
      );
      this.list = res;
    },
    reload() {
      this.fetchList();
    },
    goToGridView(list) {
      this.$router.push({
        name: "ListDetailGrid",
        path: `/profile/lists/${list.listId}/grid`,
        params: { list: list }
      });
    }
  },
  created() {
    this.reload();
  },
  watch: {
    listId() {
      this.reload();
    }
  }
};
</script>
